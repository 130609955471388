<template>
	<div>
		<div v-if="isLoading">
			<spinner></spinner>
		</div>
		<!-- search employee -->

		<filter-and-export-component :export-label="'Export Pension'" :page="page" @loading="setLoading"
			@filter-payroll="filterPayroll" @export-data="exportTierThree"
			@reset-data="setQuery"></filter-and-export-component>

		<!-- table for tier 3 -->
		<v-data-table fixed-header disable-sort height="80vh" :headers="headers" :items="tier3" class="elevation-1"
			:page.sync="page" @page-count="pageCount = $event" :items-per-page="itemsPerPage" hide-default-footer>
			<template v-slot:[`item.no`]="{ index }">
				{{ index + 1 + pageNumbering }}
			</template>
			<template v-slot:[`item.displayName`]="{ item }">
				{{ item.employee.displayName }}
			</template>
			<template v-slot:[`item.customSSN`]="{ item }">
				{{ item.employee.employeeIdentification?.customSSN }}
			</template>
			<template v-slot:[`item.tierTwoNumber`]="{ item }">
				{{ item.employee.employeeIdentification?.tierTwo }}
			</template>
			<template v-slot:[`item.basicSalary`]="{ item }">
				{{ formatFigure(item.employee.basicSalary) }}
			</template>
			<template v-slot:[`item.employerTierThreePre`]="{ item }">
				{{ formatFigure(item.employerTierThreePre) }}
			</template>
			<template v-slot:[`item.employerTierThreeAfter`]="{ item }">
				{{ formatFigure(item.employerTierThreeAfter) }}
			</template>
			<template v-slot:[`item.employeeTierThreePre`]="{ item }">
				{{ formatFigure(item.employeeTierThreePre) }}
			</template>
			<template v-slot:[`item.employeeTierThreeAfter`]="{ item }">
				{{ formatFigure(item.employeeTierThreeAfter) }}
			</template>
			<template slot="body.append">
				<tr class="primary--text footer">
					<th class="subtitle-2" id="totals">TOTALS</th>
					<th class="subtitle-2" id="th2"></th>
					<th class="subtitle-2" id="th3"></th>
					<th class="subtitle-2" id="th3"></th>
					<th class="subtitle-2 table-bordered" id="basicSalary">
						{{ formatFigure(payrollTotal.basicSalary) }}
					</th>
					<th class="subtitle-2 table-bordered" id="employerTierThreePre">
						{{ formatFigure(payrollTotal.employerTierThreePre) }}
					</th>
					<th class="subtitle-2 table-bordered" id="employerTierThreeAfter">
						{{ formatFigure(payrollTotal?.employerTierThreeAfter) }}
					</th>
					<th class="subtitle-2 table-bordered" id="employeeTierThreePre">
						{{ formatFigure(payrollTotal.employeeTierThreePre) }}
					</th>
					<th class="subtitle-2 table-bordered" id="employeeTierThreeAfter">
						{{ formatFigure(payrollTotal.employeeTierThreeAfter) }}
					</th>
				</tr>
			</template>
		</v-data-table>
		<div class="text-center pt-2">
			<v-pagination v-model="page" :length="totalPages" @input="paginatePage()" circle></v-pagination>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import FilterAndExportComponent from "./FilterAndExportComponent";
import { Parser } from "json2csv";
import generalPageMixin from "../../services/mixins/generalPageMixin";
import payrollExtraMixin from "../../services/mixins/payrollTotalsMixin";
import { tier3PayrollHeaders } from "../../services/serviceData";

export default {
	name: "Tier3",
	mixins: [generalPageMixin, payrollExtraMixin],
	components: {
		FilterAndExportComponent,
	},

	data() {
		return {
			pageCount: 0,
			itemsPerPage: 20,
			isLoading: false,
			storePath: "payroll",
			headers: [
				...tier3PayrollHeaders,
			],
		};
	},

	methods: {
		formatFigure(item) {
			return new Intl.NumberFormat("en-US", {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}).format(item);
		},

		async exportTierThree() {
			this.isLoading = true;
			const response = this.tier3;
			console.log(response);
			try {
				if (response.length > 0) {
					this.isLoading = false;

					const fileData = response.map((item, index) => {
						return {
							"NO.": index + 1,
							"SSNITNo": item.employee.employeeIdentification?.customSSN,
							"MemberNo": item.employee.employeeIdentification?.tierTwo,
							"MemberName": item.employee.displayName,
							"BasicSalary": item.employee.basicSalary,
							"EmployerPreTax": item.employerTierThreePre,
							"EmployerAfterTax": item.employerTierThreeAfter,
							"MemberPreTax": item.employeeTierThreePre,
							"MemberAfterTax": item.employeeTierThreeAfter,
						}
					});
					const parser = new Parser();
					const csv = parser.parse(fileData);
					const blob = new Blob([csv], { type: 'text/csv' });
					const date = moment(this.payrollDate).format("YYYY-MMMM");
					const departmentNames = {
						SC: "SERVICE CENTER",
						TRN: "TRAINING CENTER",
						OP: "OPERATIONS",
					};
					const departmentName = departmentNames[this.department];
					const title = `TIER THREE REPORT FOR - ${departmentName} FOR ${date}.csv`;
					const link = document.createElement("a");
					const fileLink = window.URL.createObjectURL(blob);
					link.download = title;
					link.href = fileLink;
					link.click();
					link.remove();
				}
			} catch (error) {
				this.isLoading = false;
				console.log(error);
			}
		},

		async filterPayroll(filterData) {
			this.query = filterData.query;
			await this.getPayrollTotals();
			this.isLoading = false;
		},

		async paginatePage() {
			this.isLoading = true;
			await this.getPayrollData();
			this.scrollToTop();
			this.isLoading = false;
		},
		scrollToTop() {
			window.scroll({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		},
	},
	inject: ["department"],
	computed: {
		tier3() {
			return this.$store.getters["payroll/allPayroll"];
		},
		payrollTotal() {
			return this.$store.getters["payroll/allPayrollTotals"];
		},

		pageNumbering() {
			if (this.page === 0) {
				return 0;
			} else {
				return this.page * 20 - 20;
			}
		},
		payrollDate() {
			return this.$store.getters["payroll/currentPayrollDate"];
		},
	},

	async created() {
		await this.getPayrollTotals();
		this.isLoading = false;
	},
};
</script>
