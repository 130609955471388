<template>
	<div>
		<!-- Spinner -->
		<div v-if="isLoading">
			<spinner></spinner>
		</div>
		<v-row class="mt-10 mb-">
			<v-col cols="12" sm="3" md="3">
				<v-menu
					ref="menu"
					v-model="menu"
					:close-on-content-click="false"
					:return-value.sync="payrollDate"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							dense
							solo
							flat
							background-color="white"
							placeholder="yyyy-mm"
							v-model="payrollDate"
							prepend-inner-icon="mdi-calendar"
							readonly
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="payrollDate"
						type="month"
						no-title
						scrollable
					>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menu = false">
							Cancel
						</v-btn>
						<v-btn
							text
							color="primary"
							@click="$refs.menu.save(payrollDate)"
						>
							OK
						</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>
			<v-col>
				<v-btn class="ml-1" color="primary" @click.prevent="filterJV">
					Filter
				</v-btn>
			</v-col>
			<v-col class="d-flex justify-end">
				<v-btn small color="primary" v-print="printObj"
					><v-icon class="pr-1" size="19"> mdi-cloud-upload </v-icon>
					Export Voucher</v-btn
				>
			</v-col>
		</v-row>
		<div class="white py-5 mt-5" id="printPage">
			<v-subheader class="text-center justify-center mt-4 mb-10">
				<h1 class="mx-auto">Journal Voucher</h1>
			</v-subheader>
			<v-subheader class="d-flex justify-content-between mr-13">
				<div class="ml-13">
					<label class="h2">Regular Payroll</label>
				</div>
				<div class="text-left">
					<div class="mb-4">
						<h3 class="mb-n5">Date</h3>
					</div>

					<div>
						<span> {{ getDate() }} </span>
					</div>
				</div>
			</v-subheader>
			<v-flex class="mb-10">
				<div class="col-xs-12 mx-15 pt-5">
					<table
						v-if="department === 'NSP'"
						aria-label="NSP JV table"
						class="table-bordered w-100"
					>
						<thead class="">
							<tr>
								<th scope="col">Item</th>
								<th scope="col">Debit (GHS)</th>
								<th scope="col">Credit (GHS)</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Gross Allowance - NSP</td>
								<td>{{ formatFigure(setTotals.grossAllowance) }}</td>
								<td></td>
							</tr>
							<tr>
								<td>NSP Monthly Charges @ 20%</td>
								<td></td>
								<td>{{ formatFigure(setTotals.nspMonthlyCharge) }}</td>
							</tr>
							<tr>
								<td>Net Payroll Control - NSP</td>
								<td></td>
								<td>{{ formatFigure(setTotals.netPayrollControl) }}</td>
							</tr>
							<tr>
								<td><strong>TOTAL</strong></td>
								<td>
									<strong>{{
										formatFigure(setTotals.debitTotal)
									}}</strong>
								</td>
								<td>
									<strong>{{
										formatFigure(setTotals.creditTotal)
									}}</strong>
								</td>
							</tr>
						</tbody>
					</table>

					<table v-else aria-label="JV table" class="table-bordered w-100">
						<thead class="">
							<tr>
								<th scope="col">Item</th>
								<th scope="col">Description</th>
								<th scope="col">Credit</th>
								<th scope="col">Debit</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Gross Salary</td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals.grossSalary) }}
								</td>
								<td></td>
							</tr>
							<tr>
								<td>Income Tax</td>
								<td></td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals.incomeTax) }}
								</td>
							</tr>
							<tr>
								<td>Social Security (13.5%) SSNIT</td>
								<td></td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals.socialSecurity) }}
								</td>
							</tr>
							<tr>
								<td>Social Security Fund (5%) AXIS</td>
								<td></td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals.socialSecurityFund) }}
								</td>
							</tr>
							<tr>
								<td>Provident Fund (TIER 3)</td>
								<td></td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals.tierThree) }}
								</td>
							</tr>
							<tr>
								<td>Various Staff Accounts - Loan Deduction</td>
								<td></td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals.loanDeduction) }}
								</td>
							</tr>
							<tr>
								<td>Student Loan Trust Fund</td>
								<td></td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals.studentLoanDeduction) }}
								</td>
							</tr>
							<tr>
								<td>Payroll Control</td>
								<td></td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals.netSalary) }}
								</td>
							</tr>
							<tr>
								<td><strong> TOTAL </strong></td>
								<td></td>
								<td style="text-align: center">
									<strong
										>{{ formatFigure(setTotals.grossSalary) }}
									</strong>
								</td>
								<td style="text-align: center">
									<strong>
										{{ formatFigure(setTotals.creditTotals) }}
									</strong>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</v-flex>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import helpers from "../../services/helpers";
import {
	GET_JV_REPORT_BY_DEPARTMENT,
	GET_NSP_JV_REPORT,
} from "../../services/graphql/queries/column_summations";
export default {
	mixins: [helpers],
	data: () => ({
		isLoading: false,
		menu: false,
		payrollDate: moment().format("YYYY-MM"),
		setTotals: {},
		printObj: {
			id: "printPage",
			standard: "html5",
		},
	}),
	inject: ["department"],
	computed: {
		currentYearMonth() {
			let today = new Date();
			return {
				yearMonth:
					today.getFullYear() +
					"-" +
					("0" + (today.getMonth() + 1)).slice(-2),
			};
		},
		setPayrollDate: {
			get() {
				return this.$store.getters["payroll/currentPayrollDate"];
			},
			set(val) {
				return this.$store.commit("payroll/SET_PAYROLL_DATE", val);
			},
		},

		getPayrollDate() {
			return this.$store.getters["payroll/currentPayrollDate"];
		},
	},
	methods: {
		getDate() {
			return moment(this.getPayrollDate).format("MMMM, YYYY");
		},
		async filterJV() {
			this.isLoading = true;
			this.setPayrollDate = this.payrollDate;
			await this.getTotalData();
			this.isLoading = false;
		},

		async getTotalData() {
			this.$apollo
				.mutate({
					mutation:
						this.department === "NSP"
							? GET_NSP_JV_REPORT
							: GET_JV_REPORT_BY_DEPARTMENT,
					variables:
						this.department === "NSP"
							? {
								yearMonth: this.getPayrollDate,
							}
							: {
									startWith: this.department,
								yearMonth: this.getPayrollDate,
							},
				})
				.then((response) => {
					const data = response.data;
					this.setTotals =
						this.department === "NSP"
							? data.getNspJVReportByDepartment
							: data.getJVReportByDepartment;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	async mounted() {
		await this.getTotalData();
	},
};
</script>
<style scoped>
.table-bordered {
	border: 1px solid black;
}
table {
	border-collapse: collapse;
}
th {
	border-bottom: 1px solid black;
	border-right: 1px solid black;
	text-align: center;
	font-weight: 600;
	padding: 0.4rem 0;
}
td {
	padding: 0.4rem 0;
	padding-left: 0.4rem;
	border-right: 1px solid black;
}
@media print {
	@page {
		size: landscape;
	}
}
</style>
