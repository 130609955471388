<template>
  <div>
    <template>
      <v-snackbar
        :timeout="5000"
        :value="true"
        top
        :color="isError ? 'error' : 'success'"
        center
        v-model="snackBar"
      >
        {{ snackBarText }}
      </v-snackbar>
    </template>
    <div v-if="isLoading">
      <spinner></spinner>
    </div>
    <!-- search employee -->

    <filter-and-export-component
      :export-label="'Export Bonus'"
      :page="page"
      @loading="setLoading"
      @filter-payroll="filterPayroll"
      @export-data="exportBonus"
      @reset-data="setQuery"
    ></filter-and-export-component>
    <v-data-table
      fixed-header
      height="80vh"
      :headers="headers"
      :items="bonus"
      disable-sort
      class="table-rounded elevation-2"
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
      hide-default-footer
    >
      <template v-slot:[`item.no`]="{ index }">
        {{ pageNumbering + index + 1 }}
      </template>
      <template v-slot:[`item.displayName`]="{ item }">
        {{ item?.employee?.displayName }}
      </template>
      <template v-slot:[`item.employeeId`]="{ item }">
        {{ item?.employee?.employeeId }}
      </template>
      <template v-slot:[`item.bonusTotal`]="{ item }">
        {{ formatFigure(item?.bonusTotal) }}
      </template>
      <template v-slot:[`item.totalTaxOnBonus`]="{ item }">
        {{ formatFigure(item?.totalTaxOnBonus) }}
      </template>
      <template v-slot:[`item.totalNetBonus`]="{ item }">
        {{ formatFigure(item?.totalNetBonus) }}
      </template>

      <template slot="body.append">
        <tr class="primary--text footer">
          <th class="subtitle-2" id="total">TOTALS</th>
          <th class="subtitle-2" id=""></th>
          <th class="subtitle-2" id=""></th>
          <th class="subtitle-2 table-bordered" id="bonus">
            {{ formatFigure(bonusTotals?.bonus) }}
          </th>
          <th class="subtitle-2 table-bordered" id="bonus tax">
            {{ formatFigure(bonusTotals?.bonusTax) }}
          </th>
          <th class="subtitle-2 table-bordered" id="net bonus">
            {{ formatFigure(bonusTotals?.netBonus) }}
          </th>
        </tr>
      </template>
      <template v-slot:no-data> NO DATA AVAILABLE ! </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="totalPages"
        @input="paginatePage()"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { apolloClient } from "../../services/apollo/apolloVueClient";
import { Parser } from "json2csv";
import helpers from "../../services/helpers";
import generalPageMixin from "../../services/mixins/generalPageMixin";
import extraMixin from "../../services/mixins/searchPayrollMixin";
import { createHeaders } from "../../services/functions";
import { EMPLOYEES_WITH_BONUS_MONTH_DEPT } from "../../services/graphql/queries/all_queries";
import FilterAndExportComponent from "./FilterAndExportComponent.vue";

export default {
  mixins: [helpers, generalPageMixin, extraMixin],
  components: {
    //  Paginate,
    FilterAndExportComponent,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    isLoading: false,
    isError: true,
    snackBar: false,
    snackBarText: "",
    //page: 1,
    pageCount: 0,
    itemsPerPage: 20,
    query: null,
    storePath: "payroll",
    headers: createHeaders("bonuses"),
    header: ["Name of Employee", "Employee ID", "Bonus", "Tax", "Net Bonus"],
  }),
  inject: ["department"],
  computed: {
    bonus() {
      return this.$store.getters["payroll/allPayroll"];
    },
    bonusTotals() {
      return this.$store.getters["payroll/allBonusTotals"];
    },

    pageNumbering() {
      if (this.page === 0) {
        return 0;
      } else {
        return this.page * 20 - 20;
      }
    },

    payrollDate() {
      return this.$store.getters["payroll/currentPayrollDate"];
    },
  },

  async mounted() {
    await this.getPayrollBonusTotals();
    this.isLoading = false;
  },
  methods: {
    async getPayrollBonusTotals() {
      await this.$store.dispatch("payroll/fetchBonusTotals", {
        department: this.department,
        yearMonth: this.payrollDate,
      });
    },

    async filterPayroll(filterData) {
      this.query = filterData.query;
      await this.getPayrollBonusTotals();
      this.isLoading = false;
    },

    async exportBonus() {
      this.isLoading = true;
      const getCurrentMonthYear = () => {
        const year = new Date().getFullYear();
        const month = new Date().toLocaleString("default", {
          month: "long",
        });
        return `${year} ${month}`;
      };
      apolloClient
        .query({
          query: EMPLOYEES_WITH_BONUS_MONTH_DEPT,
          variables: {
            startWithInput: this.department,
          },
        })
        .then((response) => {
          this.isLoading = false;
          const date = getCurrentMonthYear();
          const departmentNames = {
            SC: "SERVICE_CENTER",
            TRN: "TRAINING_CENTER",
            OP: "OPERATIONS",
            MG: "MANAGEMENT",
          };
          const departmentName = departmentNames[this.department];
          const title = `BONUS REPORT FOR ${departmentName} FOR ${date}.csv`;
          let fileData =
            response.data.listEmployeesWithBonusForCurrentMonthWithDepartment;
          if (fileData.length === 0) {
            fileData = [this.bonus][0];
          }
          const cleanFileData = fileData
            .map((item) => {
              return Object.entries(item)
                .filter(([key]) => key !== "__typename")
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
            })
            .map((item, index) => {
              return {
                "NO.": index + 1,
                "EMPLOYEE NAME":
                  this.department === "MG" || item.employee?.displayName
                    ? item.employee?.displayName
                    : item.displayName,
                "EMPLOYEE ID":
                  this.department === "MG" || item.employee?.employeeId
                    ? item.employee?.employeeId
                    : item.employeeId,
                "BONUS (GH₵)": item.bonusTotal,
                "TAX (GH₵)": item.totalTaxOnBonus,
                "NET BONUS (GH₵)": item.totalNetBonus,
              };
            });
          const parser = new Parser();
          const csv = parser.parse(cleanFileData);
          const blob = new Blob([csv], { type: "text/xls" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = title;
          link.click();
          link.remove();
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    async paginatePage() {
      this.isLoading = true;
      await this.getPayrollData();
      this.scrollToTop();
      this.isLoading = false;
    },

    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.v-toolbar__title {
  font-weight: bolder;
  font-size: 1.2rem;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #404040;
  font-weight: bolder;
  font-size: 0.9rem;
  background-color: #f5f6fa;
  padding: 1rem;
}

.export-pension {
  margin-top: 8rem !important;
}
</style>
