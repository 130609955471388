<template>
  <div>
    <template>
      <v-snackbar
        :timeout="5000"
        :value="true"
        top
        :color="isError ? 'error' : 'success'"
        center
        v-model="snackBar"
      >
        {{ snackBarText }}
      </v-snackbar>
    </template>
    <div v-if="isLoading">
      <spinner></spinner>
    </div>

    <!-- search employee -->

    <filter-and-export-component
      :export-label="'Export Tax'"
      :page="page"
      @loading="setLoading"
      @filter-payroll="filterPayroll"
      @export-data="exportTax"
      @reset-data="setQuery"
    ></filter-and-export-component>
    <v-data-table
      id="gra-table"
      fixed-header
      height="80vh"
      :headers="headers"
      :items="gra"
      disable-sort
      class="table-rounded elevation-2"
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
      hide-default-footer
    >
      <template v-slot:[`item.no`]="{ index }">
        {{ index + 1 + pageNumbering }}
      </template>
      <template v-slot:[`item.ghanaCard`]="{ item }">
        {{ item?.employee?.employeeIdentification?.ghanaCard }}
      </template>
      <template v-slot:[`item.fullName`]="{ item }">
        {{ item?.employee?.displayName }}
      </template>
      <template v-slot:[`item.employeePosition`]="{ item }">
        {{ item?.employee?.employeePosition }}
      </template>
      <template v-slot:[`item.nonResident`]="{ item }">
        {{ item?.employee?.nonResident }}
      </template>
      <template v-slot:[`item.basicSalary`]="{ item }">
        {{ formatFigure(item?.employee?.basicSalary) }}
      </template>
      <template v-slot:[`item.secondaryEmployment`]="{ item }">
        {{ item?.employee?.secondaryEmployment }}
      </template>
      <template v-slot:[`item.employeeSSF`]="{ item }">
        {{ formatFigure(item?.employeeSSF) }}
      </template>
      <template v-slot:[`item.totalAllowance`]="{ item }">
        {{ formatFigure(item?.totalAllowance) }}
      </template>
      <template v-slot:[`item.initialBonus`]="{ item }">
        {{ formatFigure(item?.initialBonus) }}
      </template>
      <template v-slot:[`item.totalTaxOnBonus`]="{ item }">
        {{ formatFigure(item?.totalTaxOnBonus) }}
      </template>
      <template v-slot:[`item.excessBonus`]="{ item }">
        {{ formatFigure(item?.excessBonus) }}
      </template>
      <template v-slot:[`item.employeeTierThreePre`]="{ item }">
        {{ formatFigure(item?.employeeTierThreePre) }}
      </template>
      <template v-slot:[`item.cashEmolument`]="{ item }">
        {{ formatFigure(item?.cashEmolument) }}
      </template>
      <template v-slot:[`item.vehicleElement`]="{ item }">
        {{ formatFigure(item?.vehicleElement) }}
      </template>
      <template v-slot:[`item.accommodationElement`]="{ item }">
        {{ formatFigure(item?.accommodationElement) }}
      </template>
      <template v-slot:[`item.nonCashBenefit`]="{ item }">
        {{ formatFigure(item?.nonCashBenefit) }}
      </template>
      <template v-slot:[`item.assessableIncome`]="{ item }">
        {{ formatFigure(item?.assessableIncome) }}
      </template>
      <template v-slot:[`item.taxRelief`]="{ item }">
        {{ formatFigure(item?.taxRelief) }}
      </template>
      <template v-slot:[`item.totalReliefs`]="{ item }">
        {{ formatFigure(item?.totalReliefs) }}
      </template>
      <template v-slot:[`item.chargeableIncome`]="{ item }">
        {{ formatFigure(item?.chargeableIncome) }}
      </template>
      <template v-slot:[`item.paye`]="{ item }">
        {{ formatFigure(item?.paye) }}
      </template>
      <template v-slot:[`item.overtimeIncome`]="{ item }">
        {{ formatFigure(item?.overtimeIncome) }}
      </template>
      <template v-slot:[`item.overtimeTax`]="{ item }">
        {{ formatFigure(item?.overtimeTax) }}
      </template>
      <template v-slot:[`item.totalTaxPayableToGRA`]="{ item }">
        {{ formatFigure(item?.totalTaxPayableToGRA) }}
      </template>
      <template v-slot:[`item.severancePayPaid`]="{ item }">
        {{ formatFigure(item?.severancePay) }}
      </template>
      <template v-slot:[`item?.remarks`]="{ item }">
        {{ item.remarks }}
      </template>

      <template slot="body.append">
        <tr class="primary--text footer">
          <th class="subtitle-2" id="totals">TOTALS</th>
          <th class="subtitle-2" id="tin"></th>
          <th class="subtitle-2" id="name"></th>
          <th class="subtitle-2" id="position"></th>
          <th class="subtitle-2" id="non-resident"></th>
          <th class="subtitle-2 table-bordered" id="basic-salary">
            {{ formatFigure(payrollTotal?.basicSalary) }}
          </th>
          <th class="subtitle-2 table-bordered" id="secondary-employment"></th>
          <th class="subtitle-2 table-bordered" id="ssf">
            {{ formatFigure(payrollTotal?.employeeSSF) }}
          </th>
          <th class="subtitle-2 table-bordered" id="third-tier">
            {{ formatFigure(payrollTotal?.employeeTierThreePre) }}
          </th>
          <th class="subtitle-2 table-bordered" id="cash-allowances">
            {{ formatFigure(payrollTotal?.allowance) }}
          </th>
          <th class="subtitle-2 table-bordered" id="bonus-income">
            {{ formatFigure(payrollTotal?.bonusTotal) }}
          </th>
          <th class="subtitle-2 table-bordered" id="final-tax">
            {{ formatFigure(payrollTotal?.totalTaxOnBonus) }}
          </th>
          <th class="subtitle-2 table-bordered" id="excess-bonus">
            {{ formatFigure(payrollTotal?.excessBonus) }}
          </th>
          <th class="subtitle-2 table-bordered" id="cash-emolument">
            {{ formatFigure(payrollTotal?.grossSalary) }}
          </th>
          <th class="subtitle-2 table-bordered" id="accommodation-element">
            0.00
          </th>
          <th class="subtitle-2 table-bordered" id="vehicle-element">0.00</th>
          <th class="subtitle-2 table-bordered" id="non-cash-benefit">0.00</th>
          <th class="subtitle-2 table-bordered" id="assessable-income">
            {{ formatFigure(payrollTotal?.assessableIncome) }}
          </th>
          <th class="subtitle-2 table-bordered" id="deductible-reliefs">
            {{ formatFigure(payrollTotal?.taxRelief) }}
          </th>
          <th class="subtitle-2 table-bordered" id="total-reliefs">
            {{ formatFigure(payrollTotal?.totalRelief) }}
          </th>
          <th class="subtitle-2 table-bordered" id="chargeable-income">
            {{ formatFigure(payrollTotal?.chargeableIncome) }}
          </th>
          <th class="subtitle-2 table-bordered" id="tax-deductible">
            {{ formatFigure(payrollTotal?.paye) }}
          </th>
          <th class="subtitle-2 table-bordered" id="overtime-income">0.00</th>
          <th class="subtitle-2 table-bordered" id="overtime-tax">0.00</th>
          <th class="subtitle-2 table-bordered" id="total-tax-payable">
            {{ formatFigure(payrollTotal?.totalTaxPayableToGRA) }}
          </th>
          <th class="subtitle-2 table-bordered" id="severance-pay-paid">
            0.00
          </th>
          <th class="subtitle-2 table-bordered" id="remarks"></th>
        </tr>
      </template>
      <template v-slot:no-data> NO DATA AVAILABLE ! </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="totalPages"
        @input="paginatePage()"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { EXPORT_GRA_REPORT } from "../../services/graphql/queries/payroll_tier_queries";
import helpers from "../../services/helpers";
import generalPageMixin from "../../services/mixins/generalPageMixin";
import payrollExtraMixin from "../../services/mixins/payrollTotalsMixin";
import { createHeaders } from "../../services/functions";
import { graPayrollHeader } from "../../services/serviceData";
import FilterAndExportComponent from "./FilterAndExportComponent.vue";

export default {
  mixins: [helpers, generalPageMixin, payrollExtraMixin],
  components: {
    //  Paginate,
    FilterAndExportComponent,
  },
  data: () => ({
    height: "70vh",
    dialog: false,
    dialogDelete: false,
    isLoading: false,
    isError: true,
    snackBar: false,
    snackBarText: "",
    pageCount: 0,
    itemsPerPage: 20,
    query: null,
    storePath: "payroll",
    headers: createHeaders("graPayroll"),
    header: [ ...graPayrollHeader ],

    pensions: [],
  }),
  inject: ["department"],
  computed: {
    gra() {
      return this.$store.getters["payroll/allPayroll"];
    },
    payrollTotal() {
      return this.$store.getters["payroll/allPayrollTotals"];
    },
    pageNumbering() {
      if (this.page === 0) {
        return 0;
      } else {
        return this.page * 20 - 20;
      }
    },

    payrollDate() {
      return this.$store.getters["payroll/currentPayrollDate"];
    },
  },
  async mounted() {
    await this.getPayrollTotals();
    this.isLoading = false;
  },
  methods: {
    async filterPayroll(filterData) {
      this.query = filterData.query;
      await this.getPayrollTotals();
      this.isLoading = false;
    },

    async exportTax() {
      this.isLoading = true;
      this.$apollo
        .query({
          query: EXPORT_GRA_REPORT,
          variables: {
            employeeName: this.query || "",
            yearMonth: this.payrollDate,
            startWith: this.department,
            responseType: "blob",
            headers: { Accept: "multipart/form-data" },
          },
        })
        .then((response) => {
          this.isLoading = false;
          const date = moment(this.payrollDate).format("YYYY-MMMM");
          const departmentNames = {
            SC: "SERVICE_CENTER",
            TRN: "TRAINING_CENTER",
            OP: "OPERATIONS",
          };
          const departmentName = departmentNames[this.department];
          const title = `GRA REPORT FOR - ${departmentName} FOR ${date}.xls`;
          const fileLink = response.data.exportGRAReport;
          const a = Object.assign(document.createElement("a"), {
            href: fileLink,
            download: title,
          });
          a.click();
          a.remove();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    async paginatePage() {
      this.isLoading = true;
      await this.getPayrollData();
      this.scrollToTop();
      this.isLoading = false;
    },

    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.v-toolbar__title {
  font-weight: bolder;
  font-size: 1.2rem;
}

#gra-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(3),
#gra-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(3) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 6;
  background: white;
}

#gra-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(3) {
  z-index: 7 !important;
  background: #f5f6fa;
}
</style>
