<template>
  <div >
    <template >
      <v-tabs v-if="department==='NSP'" v-model="tab" background-color="transparent" grow>
        <v-tab v-for="item in nspItems" :key="item.tab">{{ item.tab }}</v-tab>
      </v-tabs>

      <v-tabs v-else v-model="tab" background-color="transparent" grow>
        <v-tab v-for="item in updateItems" :key="item.tab">{{ item.tab }}</v-tab>
      </v-tabs>
    </template>
    <template >
      <slot></slot>
    </template>
    <template>
      <v-tabs-items v-if="department==='NSP'" v-model="tab" >
        <v-tab-item v-for="item in nspItems" :key="item.tab" >
          <component :is="item.content" ></component>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-else v-model="tab" >
        <v-tab-item v-for="item in items" :key="item.tab" >
          <component :is="item.content" ></component>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </div>
</template>

<script>
import Payroll from '@/components/payrolls/PayrollComponent.vue';
import Tier1 from '@/components/payrolls/Tier1Payroll.vue';
import Tier2 from '@/components/payrolls/Tier2Payroll.vue';
import Tier3 from '@/components/payrolls/Tier3Payroll.vue';
import GRA from '@/components/payrolls/GraPayroll.vue';
import BankAdvice from '@/components/payrolls/BankAdvice.vue';
import Bonuses from '@/components/payrolls/BonusesComponent.vue';
import JournalVoucher from '@/components/payrolls/JournalVoucher.vue';
import NspAmortization from '@/components/payrolls/NspAmortization.vue'
import TeamPerformance from '../payrolls/TeamPerformance.vue';
import { tabsItems, tabsNspItems } from '../../services/serviceData';

export default {
  components: {
    Payroll,
    Tier1,
    Tier2,
    Tier3,
    GRA,
    BankAdvice,
    Bonuses,
    JournalVoucher,
    NspAmortization,
    TeamPerformance,
  },
  data: () => ({
    //tab: null,
    items: [ ...tabsItems],
    nspItems: [ ...tabsNspItems],
    
  }),
   inject: ['department'],
  computed: {
    updateItems() {
      if(this.department !== "SC"){
        const newItems = JSON.parse(JSON.stringify(this.items));
        const tpIndex = newItems.findIndex(item => item.tab === 'Team Performance');
        if(tpIndex !== -1){
          newItems[tpIndex].tab = "KPI";
        }
        return newItems
      }else {
        return this.items;
      }
    },
    tab: {
      get() {
        return this.$store.getters.payrollTab
      },
      set(val) {
        this.$store.commit('SET_PAYROLL_TAB', val)
      },
    }
  },
 

};
</script>